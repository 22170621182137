import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f5ef3972 = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _10dcac50 = () => interopDefault(import('../pages/lp1.vue' /* webpackChunkName: "pages/lp1" */))
const _10c07d4e = () => interopDefault(import('../pages/lp2.vue' /* webpackChunkName: "pages/lp2" */))
const _10a44e4c = () => interopDefault(import('../pages/lp3.vue' /* webpackChunkName: "pages/lp3" */))
const _f90680f8 = () => interopDefault(import('../pages/redirect/index.vue' /* webpackChunkName: "pages/redirect/index" */))
const _d58a3666 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _1671e281 = () => interopDefault(import('../pages/home/coupon.vue' /* webpackChunkName: "pages/home/coupon" */))
const _50bb0fee = () => interopDefault(import('../pages/JP/lp1.vue' /* webpackChunkName: "pages/JP/lp1" */))
const _509ee0ec = () => interopDefault(import('../pages/JP/lp2.vue' /* webpackChunkName: "pages/JP/lp2" */))
const _5082b1ea = () => interopDefault(import('../pages/JP/lp3.vue' /* webpackChunkName: "pages/JP/lp3" */))
const _3274c5bc = () => interopDefault(import('../pages/payment/bindcard-successful.vue' /* webpackChunkName: "pages/payment/bindcard-successful" */))
const _26d62435 = () => interopDefault(import('../pages/payment/othercard-successful.vue' /* webpackChunkName: "pages/payment/othercard-successful" */))
const _44cc110f = () => interopDefault(import('../pages/payment/payment-successful.vue' /* webpackChunkName: "pages/payment/payment-successful" */))
const _3e0a98bb = () => interopDefault(import('../pages/payment/post-bindcard-successful.vue' /* webpackChunkName: "pages/payment/post-bindcard-successful" */))
const _18cffc79 = () => interopDefault(import('../pages/payment/pre-payment-successful.vue' /* webpackChunkName: "pages/payment/pre-payment-successful" */))
const _2026676b = () => interopDefault(import('../pages/payment/result.vue' /* webpackChunkName: "pages/payment/result" */))
const _1c151214 = () => interopDefault(import('../pages/payment/result-iframe.vue' /* webpackChunkName: "pages/payment/result-iframe" */))
const _67f67965 = () => interopDefault(import('../pages/success/agency-thank-you.vue' /* webpackChunkName: "pages/success/agency-thank-you" */))
const _7baf9996 = () => interopDefault(import('../pages/success/leave-info.vue' /* webpackChunkName: "pages/success/leave-info" */))
const _2f22a440 = () => interopDefault(import('../pages/unsubscription/lead.vue' /* webpackChunkName: "pages/unsubscription/lead" */))
const _3dfb1408 = () => interopDefault(import('../pages/bestpractice/_region.vue' /* webpackChunkName: "pages/bestpractice/_region" */))
const _10d3b582 = () => interopDefault(import('../pages/ecommerce-new/_region.vue' /* webpackChunkName: "pages/ecommerce-new/_region" */))
const _22a1efb0 = () => interopDefault(import('../pages/error/_code.vue' /* webpackChunkName: "pages/error/_code" */))
const _5faba04d = () => interopDefault(import('../pages/lead-form/_sign.vue' /* webpackChunkName: "pages/lead-form/_sign" */))
const _0990f027 = () => interopDefault(import('../pages/supportblackbusinesses/_region.vue' /* webpackChunkName: "pages/supportblackbusinesses/_region" */))
const _5bcb9497 = () => interopDefault(import('../pages/tt4b/_region.vue' /* webpackChunkName: "pages/tt4b/_region" */))
const _22c0f653 = () => interopDefault(import('../pages/tt4bv2/_region.vue' /* webpackChunkName: "pages/tt4bv2/_region" */))
const _414d2ab4 = () => interopDefault(import('../pages/ttba-casestudy/_region.vue' /* webpackChunkName: "pages/ttba-casestudy/_region" */))
const _6a748ddb = () => interopDefault(import('../pages/ttba-ecommerce/_region.vue' /* webpackChunkName: "pages/ttba-ecommerce/_region" */))
const _23c17b9a = () => interopDefault(import('../pages/_slug/_lang.vue' /* webpackChunkName: "pages/_slug/_lang" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    redirect: "/tt4b",
    name: "index"
  }, {
    path: "/savannah/:region?",
    redirect: "/ecommerce-new"
  }, {
    path: "/b2bcredit-lead/:region?",
    redirect: "/ecommerce-new"
  }, {
    path: "/holiday-2020/:region?",
    redirect: "/ecommerce-new"
  }, {
    path: "/holiday/:region?",
    redirect: "/ecommerce-new"
  }, {
    path: "/holidayr/:region?",
    redirect: "/ecommerce-new"
  }, {
    path: "/holidayb/:region?",
    redirect: "/ecommerce-new"
  }, {
    path: "/b2bs/:region?",
    redirect: "/ecommerce-new"
  }, {
    path: "/princesspolly/:region?",
    redirect: "/ecommerce-new"
  }, {
    path: "/ecommerce/:region?",
    redirect: "/ecommerce-new"
  }, {
    path: "/caseslatetell/:region?",
    redirect: "/ecommerce-new"
  }, {
    path: "/casestudy/:region?",
    redirect: "/ecommerce-new"
  }, {
    path: "/b2bcredit-ecommerce/:region?",
    redirect: "/ecommerce-new"
  }, {
    path: "/b2bcredit-casestudy/:region?",
    redirect: "/ecommerce-new"
  }, {
    path: "/b2bcredit-caseslatetell/:region?",
    redirect: "/ecommerce-new"
  }, {
    path: "/b2bcredit-new/:region?",
    redirect: "/ecommerce-new"
  }, {
    path: "/tt4bnew-new/:region?",
    redirect: "/ecommerce-new"
  }, {
    path: "/tt4b-new/:region?",
    redirect: "/ecommerce-new"
  }, {
    path: "/b2bcredit/tr",
    redirect: "/tt4bnew/tr"
  }, {
    path: "/b2bcredit/mena",
    redirect: "/tt4bnew/mena"
  }, {
    path: "/b2bcredit/ko",
    redirect: "/tt4bnew/ko"
  }, {
    path: "/b2bcredit/ru",
    redirect: "/tt4bnew/ru"
  }, {
    path: "/b2bcredit/ms",
    redirect: "/tt4bnew/ms"
  }, {
    path: "/b2bcredit/ja",
    redirect: "/tt4bnew/ja"
  }, {
    path: "/b2bcredit/jp",
    redirect: "/tt4bnew/jp"
  }, {
    path: "/b2bcredit/in",
    redirect: "/tt4bnew/in"
  }, {
    path: "/b2bcredit/de",
    redirect: "/tt4bnew/de"
  }, {
    path: "/b2bcredit/es",
    redirect: "/tt4bnew/es"
  }, {
    path: "/b2bcredit/it",
    redirect: "/tt4bnew/it"
  }, {
    path: "/b2bcredit/fr",
    redirect: "/tt4bnew/fr"
  }, {
    path: "/b2bcredit/id",
    redirect: "/tt4bnew/id"
  }, {
    path: "/b2bcredit/sea",
    redirect: "/tt4bnew/sea"
  }, {
    path: "/b2bcredit/th",
    redirect: "/tt4bnew/th"
  }, {
    path: "/b2bcredit/vi",
    redirect: "/tt4bnew/vi"
  }, {
    path: "/b2bcredit",
    redirect: "/tt4bnew"
  }, {
    path: "/redirect",
    redirect: "/tt4bnew"
  }, {
    path: "/b2bcredit/:region",
    redirect: "/tt4bnew"
  }, {
    path: "/home",
    component: _f5ef3972,
    name: "home"
  }, {
    path: "/lp1",
    component: _10dcac50,
    name: "lp1"
  }, {
    path: "/lp2",
    component: _10c07d4e,
    name: "lp2"
  }, {
    path: "/lp3",
    component: _10a44e4c,
    name: "lp3"
  }, {
    path: "/redirect",
    component: _f90680f8,
    name: "redirect"
  }, {
    path: "/search",
    component: _d58a3666,
    name: "search"
  }, {
    path: "/home/coupon",
    component: _1671e281,
    name: "home-coupon"
  }, {
    path: "/JP/lp1",
    component: _50bb0fee,
    name: "JP-lp1"
  }, {
    path: "/JP/lp2",
    component: _509ee0ec,
    name: "JP-lp2"
  }, {
    path: "/JP/lp3",
    component: _5082b1ea,
    name: "JP-lp3"
  }, {
    path: "/payment/bindcard-successful",
    component: _3274c5bc,
    name: "payment-bindcard-successful"
  }, {
    path: "/payment/othercard-successful",
    component: _26d62435,
    name: "payment-othercard-successful"
  }, {
    path: "/payment/payment-successful",
    component: _44cc110f,
    name: "payment-payment-successful"
  }, {
    path: "/payment/post-bindcard-successful",
    component: _3e0a98bb,
    name: "payment-post-bindcard-successful"
  }, {
    path: "/payment/pre-payment-successful",
    component: _18cffc79,
    name: "payment-pre-payment-successful"
  }, {
    path: "/payment/result",
    component: _2026676b,
    name: "payment-result"
  }, {
    path: "/payment/result-iframe",
    component: _1c151214,
    name: "payment-result-iframe"
  }, {
    path: "/success/agency-thank-you",
    component: _67f67965,
    name: "success-agency-thank-you"
  }, {
    path: "/success/leave-info",
    component: _7baf9996,
    name: "success-leave-info"
  }, {
    path: "/unsubscription/lead",
    component: _2f22a440,
    name: "unsubscription-lead"
  }, {
    path: "/bestpractice/:region?",
    component: _3dfb1408,
    name: "bestpractice-region"
  }, {
    path: "/ecommerce-new/:region?",
    component: _10d3b582,
    name: "ecommerce-new-region"
  }, {
    path: "/error/:code?",
    component: _22a1efb0,
    name: "error-code"
  }, {
    path: "/lead-form/:sign?",
    component: _5faba04d,
    name: "lead-form-sign"
  }, {
    path: "/supportblackbusinesses/:region?",
    component: _0990f027,
    name: "supportblackbusinesses-region"
  }, {
    path: "/tt4b/:region?",
    component: _5bcb9497,
    name: "tt4b-region"
  }, {
    path: "/tt4bv2/:region?",
    component: _22c0f653,
    name: "tt4bv2-region"
  }, {
    path: "/ttba-casestudy/:region?",
    component: _414d2ab4,
    name: "ttba-casestudy-region"
  }, {
    path: "/ttba-ecommerce/:region?",
    component: _6a748ddb,
    name: "ttba-ecommerce-region"
  }, {
    path: "/:slug?/:lang?",
    component: _23c17b9a,
    name: "slug-lang"
  }, {
    path: "/home/:country",
    component: _1671e281,
    name: "index-home"
  }, {
    path: "*",
    redirect: "/error/404",
    name: "error"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
