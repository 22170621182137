export default function (to, from, savedPosition) {
  if (process.client) {
    try {
      let hash = to.hash.replace(/(.+)#/, '$1')
      if (hash) {
        const el = document.querySelector(hash)
        if (el) {
          window.scrollTo(0, el.offsetTop);
        }
      }
    } catch (e) {
      console.error(e)
    }
  }
}
